<template>
  <div class="service-tab">
    <div class="service-tab_fixed c-width">
      <div class="tab">
        <div
          class="item"
          v-for="(item, index) in filterTabData"
          :key="index"
          @click="switchDocId(index)"
        >
          <a :href="item.docId" class="font">
            <span :class="{ active_color: selecValue===item.docId }">
              {{item.value}}
              <div :class="{ active_line: selecValue===item.docId }"></div>
            </span>
          </a>
        </div>
      </div>
      <div class="search">
        <el-input
          placeholder="搜索本服务内容"
          v-model="keyword"
          @search="search"
          @keyup.enter.native="search"
        >
          <template slot="append">
            <el-button @click="search">搜索</el-button>
          </template>
        </el-input>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tabData: {
      type: Array,
      default() {
        return []
      }
    },
    value: {
      type: String,
      default: ''
    },
    tabDocId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      keyword: this.value,
      selecValue: this.$route.params.id
    }
  },
  methods: {
    search() {
      if (this.keyword) {
        this.$emit('update:val', this.keyword)
        this.$emit('search')
      }
    },
    switchDocId(doc_id) {
      this.selectIndex = doc_id
    }
  },
  computed: {
    filterTabData() {
      let newArr = []
      this.tabData.filter((element) => {
        if (element.product_id === this.tabDocId) {
          newArr = element.tab_value_arr
        }
      })
      return newArr
    }
  }
}
</script>

<style lang="less" scoped>
@import '../../../assets/less/varible.less';
.service-tab {
  width: 100%;
  &_fixed {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 64px;
    .tab {
      display: flex;
      .item {
        .font-style {
          font-weight: 400;
          font-size: 16px;
        }
        padding-right: 60px;
        .active_color {
          color: @mainColor;
          .font-style;
        }
        .active_line {
          height: 2px;
          background: @mainColor;
          position: relative;
          top: 20px;
        }
        .font {
          color: @hoversColor;
          .font-style;
        }
        &:hover {
          span {
            color: @mainColor;
          }
        }
      }
    }
    // 修改element样式
    /deep/ .el-input-group__append {
      background: @mainColor;
      border: 1px solid @mainColor;
      color: @whiteBcgColor;
    }
    .search {
      /deep/ .el-input__inner::placeholder {
        color: @placeHolderColor;
      }
    }
  }
}
</style>
