//
const service_status = {
  1: '直播服务',
  2: '点播服务',
  3: '聊天服务',
  5: '音视频互动服务',
  4: '文档白板服务',
  6: '表单服务',
  7: '推送通知服务'
}
const service_value_statue = {
  live_streaming: 1,
  dibbling: 2,
  chat: 3,
  interactive: 5,
  doc_white_board: 4,
  form: 6,
  push_notice: 7
}
const service_tab_status = {
  0: '产品介绍',
  1: 'API文档',
  2: 'SDK文档',
  3: 'Demo体验',
  4: '常见问答'
}
const service_tab_value_satus = {
  pro_desc: 0,
  api_doc: 1,
  sdk_doc: 2,
  demo_expe: 3,
  common_ques_ask: 4
}
const tab_data = [
  {
    product_id: '直播服务',
    tab_value_arr: [
      {
        docId: '2',
        value: '产品介绍'
      },
      {
        docId: '73',
        value: 'API文档'
      },
      {
        docId: '173',
        value: 'SDK文档'
      },
      {
        docId: '706',
        value: 'Demo体验'
      },
      {
        docId: '176',
        value: '常见问答'
      }
    ]
  },
  {
    product_id: '点播服务',
    tab_value_arr: [
      {
        docId: '181',
        value: '产品介绍'
      },
      {
        docId: '190',
        value: 'API文档'
      },
      {
        docId: '701',
        value: 'SDK文档'
      },
      {
        docId: '693',
        value: 'Demo体验'
      },
      {
        docId: '704',
        value: '常见问答'
      }
    ]
  },
  {
    product_id: '聊天服务',
    tab_value_arr: [
      {
        docId: '338',
        value: '产品介绍'
      },
      {
        docId: '352',
        value: 'API文档'
      },
      {
        docId: '413',
        value: 'SDK文档'
      },
      {
        docId: '707',
        value: 'Demo体验'
      },
      {
        docId: '407',
        value: '常见问答'
      }
    ]
  },
  {
    product_id: '音视频互动服务',
    tab_value_arr: [
      {
        docId: '453',
        value: '产品介绍'
      },
      {
        docId: '481',
        value: 'API文档'
      },
      {
        docId: '554',
        value: 'SDK文档'
      },
      {
        docId: '549',
        value: 'Demo体验'
      },
      {
        docId: '557',
        value: '常见问答'
      }
    ]
  },
  {
    product_id: '文档白板服务',
    tab_value_arr: [
      {
        docId: '560',
        value: '产品介绍'
      },
      {
        docId: '569',
        value: 'API文档'
      },
      {
        docId: '626',
        value: 'SDK文档'
      },
      {
        docId: '621',
        value: 'Demo体验'
      },
      {
        docId: '629',
        value: '常见问答'
      }
    ]
  },
  {
    product_id: '表单服务',
    tab_value_arr: [
      {
        docId: '272',
        value: '产品介绍'
      },
      {
        docId: '281',
        value: 'API文档'
      },
      {
        docId: '329',
        value: 'Demo体验'
      },
      {
        docId: '332',
        value: '常见问答'
      }
    ]
  },
  {
    product_id: '推送通知服务',
    tab_value_arr: [
      {
        docId: '348',
        value: '产品介绍'
      },
      {
        docId: '350',
        value: 'API文档'
      }
    ]
  },
  {
    product_id: '数据服务',
    tab_value_arr: [
      {
        docId: '759',
        value: '产品介绍'
      },
      {
        docId: '761',
        value: 'API文档'
      },
      {
        docId: '815',
        value: '功能实践'
      }
    ]
  }
]
export default {
  service_status,
  service_value_statue,
  service_tab_status,
  service_tab_value_satus,
  tab_data
}
