<template>
  <div class="select">
    <el-select v-model="serviceValue" placeholder="请选择" @change="changeValue">
      <el-option
        v-for="item in serviceOptions"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      ></el-option>
    </el-select>
  </div>
</template>

<script>
export default {
  props: {
    serviceOptions: {
      type: Array,
      default: () => []
    },
    docType: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      // keyword: this.val
      serviceValue: this.docType
    }
  },
  methods: {
    changeValue(item) {
      this.$emit('selectValue', item)
    }
    // search () {
    //   if (this.keyword) {
    //     this.$emit('update:val', this.keyword)
    //     this.$emit('search')
    //   }
    // }
  },
  watch: {
    docType(val) {
      this.serviceValue = val
    }
  }
}
</script>
<style lang="less" scoped>
@import '../../../assets/less/varible.less';
.select {
}
</style>
