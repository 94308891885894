<template>
  <div class="detail__wrap">
    <c-header :isDocumentCenter="true" @selectDocId="selectDocId"></c-header>
    <c-tab :val.sync="keyword" :tabDocId="doc_type" :tabData="tabData" @search="search"></c-tab>
    <div class="detail-wrap">
      <div class="detail c-width" :class="{ fix: isFix }">
        <div class="detail__menu">
          <div class="detail__menu-nav">
            <!-- <h3>
              <a :href="docIndexHref">文档中心</a>
            </h3>
            <p class="detail__menu-name">
              <a href>{{ doc_type }}</a>
            </p>-->
            <div class="detail__menu-search">
              <service-select
                @selectValue="selectDocId"
                :serviceOptions="serviceOptions"
                :docType="doc_type"
              ></service-select>
              <!-- <el-input
                placeholder="搜索本服务内容"
                v-model="keyword"
                @search="search"
                @keyup.enter.native="search"
              >
              </el-input>
              <i slot="append" class="iconfont iconsousuo" @click="search"></i>-->
            </div>
            <!-- <div class="line"></div> -->
          </div>
          <div class="menu">
            <el-menu
              class="el-menu-vertical-demo"
              :default-active="`~${active_id}`"
              :unique-opened="true"
            >
              <template v-for="(firstMenu, index) in menuData">
                <el-menu-item
                  class="first-menu first"
                  :index="`~${firstMenu.local.doc_id}`"
                  v-if="!firstMenu.child.length"
                  :key="index"
                  @click="
                    getHtml(
                      firstMenu.local.project_id,
                      firstMenu.local.doc_id,
                      false
                    )
                  "
                >
                  <span>{{ firstMenu.local.doc_name }}</span>
                </el-menu-item>
                <el-submenu
                  v-else
                  :key="index"
                  :index="`~${firstMenu.local.doc_id}`"
                  class="first-menu"
                >
                  <template slot="title">
                    <span>{{ firstMenu.local.doc_name }}</span>
                    <i class="iconfont iconicon-xialajiantou"></i>
                  </template>
                  <template v-for="(secondMenu, index) in firstMenu.child">
                    <el-menu-item
                      class="childMenu secondMenu"
                      :index="`~${secondMenu.local.doc_id}`"
                      v-if="!secondMenu['child']"
                      :key="index"
                      @click="
                        getHtml(
                          secondMenu.local.project_id,
                          secondMenu.local.doc_id,
                          false
                        )
                      "
                    >{{ secondMenu.local.doc_name }}</el-menu-item>
                    <el-submenu
                      class="childMenu"
                      v-else
                      :key="index"
                      :index="`~${secondMenu.local.doc_id}`"
                    >
                      <template slot="title">
                        <span>{{ secondMenu.local.doc_name }}</span>
                        <i class="iconfont iconicon-xialajiantou"></i>
                      </template>
                      <template v-for="(thirdMenu, index) in secondMenu.child">
                        <el-menu-item
                          class="childMenu thirdMenu"
                          v-if="!thirdMenu['child']"
                          :key="index"
                          :index="`~${thirdMenu.local.doc_id}`"
                          @click="
                            getHtml(
                              thirdMenu.local.project_id,
                              thirdMenu.local.doc_id,
                              false
                            )
                          "
                        >{{ thirdMenu.local.doc_name }}</el-menu-item>
                        <el-submenu
                          v-else
                          :key="index"
                          class="childMenu"
                          :index="`~${thirdMenu.local.doc_id}`"
                        >
                          <template slot="title">
                            <span>{{ thirdMenu.local.doc_name }}</span>
                            <i class="iconfont iconicon-xialajiantou"></i>
                          </template>
                          <template v-for="(fourthMenu, index) in thirdMenu.child">
                            <el-menu-item
                              class="childMenu"
                              :index="`~${fourthMenu.local.doc_id}`"
                              v-if="!fourthMenu['child']"
                              :key="index"
                              @click="
                                getHtml(
                                  fourthMenu.local.project_id,
                                  fourthMenu.local.doc_id,
                                  false
                                )
                              "
                            >{{ fourthMenu.local.doc_name }}</el-menu-item>
                            <el-submenu
                              class="childMenu"
                              v-else
                              :key="index"
                              :index="`~${fourthMenu.local.doc_id}`"
                            >
                              <template slot="title">
                                <span>{{ fourthMenu.local.doc_name }}</span>
                                <i class="iconfont iconicon-xialajiantou"></i>
                              </template>
                            </el-submenu>
                          </template>
                        </el-submenu>
                      </template>
                    </el-submenu>
                  </template>
                </el-submenu>
              </template>
            </el-menu>
          </div>
        </div>
        <div class="detail__content" id="docContent">
          <div class="con">
            <div class="detail__content-breadcrumb" v-html="bread"></div>
            <div class="detail__content-body" v-html="markdownToHtml" @click="debugBtn($event)"></div>
          </div>
          <copy-right class="copyright" :isDocs="true"></copy-right>
        </div>
      </div>
    </div>
    <back-top></back-top>
  </div>
</template>

<script>
import cHeader from '../layout/components/header'
import backTop from '../layout/components/backToTop'
import copyRight from '../layout/components/copyright'
import serviceSelect from './components/serviceSelect'
import cTab from './components/docTab.vue'
import global_val from './global/global_val'
import marked from 'marked'
export default {
  components: { cHeader, backTop, copyRight, serviceSelect, cTab },
  data() {
    return {
      bread: '',
      body: '',
      menu: '',
      isFix: false,
      docId: '',
      project_id: '',
      keyword: '',
      menuData: [],
      markdownToc: '',
      menuLists: [], // 包含本页面右侧标题及其句顶部的距离
      isClick: false, // 是否存在点击
      doc_type: '',
      hash: '',
      timer: null,
      anchorTimer: null,
      getEleTimer: null,
      markTocListEle: null, // 右侧固定列表ul
      markTocEle: null, // 右侧固定列表wrap层
      docIndexHref: '',
      active_id: '',
      serviceOptions: [
        {
          value: '2',
          label: '直播服务'
        },
        {
          value: '181',
          label: '点播服务'
        },
        {
          value: '453',
          label: '音视频互动服务'
        },
        {
          value: '560',
          label: '文档白板服务'
        },
        {
          value: '338',
          label: '聊天服务'
        },
        {
          value: '272',
          label: '表单服务'
        },
        {
          value: '318',
          label: '推送通知服务'
        },
        {
          value: '759',
          label: '数据服务'
        }
      ],
      tabData: global_val.tab_data,
      LiHeight: 0
    }
  },
  computed: {
    markdownToHtml() {
      return marked(this.body)
    }
  },
  created() {
    this.cur_id = this.$route.params.id
    if (typeof this.cur_id === 'string' && this.cur_id.includes('.html')) {
      this.docId = this.cur_id.split('.')[0]
    } else {
      this.docId = this.cur_id
    }
    this.docIndexHref = `${window.urlConfig.docUrl}document/document/index`
    this.getLeftMenu()
  },
  mounted() {
    if (window.history && window.history.pushState) {
      window.addEventListener('popstate', this.goBack, false)
    }
    document.addEventListener('mousewheel', this.autoScrollFun, false)
    if (window.location.hash) {
      this.hash = decodeURIComponent(window.location.hash).split('#')[1]
    }
    window.addEventListener(
      'scroll',
      () => {
        this.scrollFun()
      },
      false
    )
  },
  updated() {
    if (window.location.hash) {
      this.hash = decodeURIComponent(window.location.hash).split('#')[1]
    }
    if (this.markdownToHtml && this.hash) {
      document.getElementById(this.hash).scrollIntoView()
    }
  },
  watch: {
    LiHeight(val, oldval) {
      // let markTocEleScroHeight = this.markTocEle.scrollTop
      let markTocEleHeight = this.markTocEle.clientHeight
      // if (!this.$route.hash) {
      if (val > markTocEleHeight - 32) {
        this.markTocEle.scrollTop += val - oldval
      } else {
        this.markTocEle.scrollTop = 0
      }
      // console.log(val, markTocEleScroHeight, markTocEleHeight)
      // }
    }
  },
  methods: {
    autoScrollFun() {
      let markTocLi = document.querySelector('.active')
      if (!markTocLi) return
      if (markTocLi.className && markTocLi.className.indexOf('second') != -1) {
        this.LiHeight =
          markTocLi.parentNode.parentNode.offsetTop + markTocLi.offsetTop
      } else {
        this.LiHeight = markTocLi.offsetTop
      }
    },
    // 内容区域解析markdown
    async debugBtn(event) {
      if (event.target.nodeName === 'BUTTON') {
        // 获取触发事件对象的属性
        const cookieArr = document.cookie.split(';')
        const tokenStr = cookieArr.filter((el) => {
          const elKey = el.split('=')[0].trim()
          if (elKey === 'token') {
            return el
          }
        })
        let doc_id = this.$route.params.id
        try {
          const { data } = await this.$fetch('jumpDebugger', {
            doc_id: this.$route.params.id,
            token: tokenStr[0] && tokenStr[0].split('=')[1]
          })
          window.open(data, '_blank ')
        } catch (error) {
          let consoleLoginUrl = `${window.urlConfig.consoleUrl}loginLayout/login?debug=${doc_id}`
          // let consoleLoginUrl = `${window.urlConfig.consoleUrl}loginLayout/login?debug=1`
          window.open(consoleLoginUrl, '_blank ')
          console.log(error)
        }
      }
    },
    selectDocId(data) {
      this.$router.push({
        name: 'docs/show',
        params: {
          id: data
        }
      })
      window.location.reload()
      // this.docId = data
      // this.getLeftMenu()
    },
    goBack() {
      const href = location.href
      window.location.href = href
    },
    getActiveId(value) {
      // 当从面包屑点击导航菜单时，默认返回第一个子菜单
      if (!value) {
        value = this.docId
      }
      const jsonData = JSON.stringify(this.menuData)
      const reg = new RegExp(
        '(\\w+),"project_id":\\d+,"parent_id":' + value + '}',
        'g'
      )
      const res = jsonData.match(reg)
      let current_active_id = null
      if (!res) {
        current_active_id = value.toString()
      } else {
        current_active_id = res[0].split(',')[0].toString()
        let child = this.getActiveId(current_active_id)
        // this.active_id = active_docid
        if (child !== current_active_id) {
          current_active_id = child
        }
      }
      this.active_id = current_active_id
      return this.active_id
    },
    getEle(name) {
      return document.querySelector(name)
    },
    removeTocActiveClass(data, idx) {
      data.forEach((el) => {
        // console.log(el.scrollHeight)
        if (el.classList && el.classList.contains('first')) {
          el.classList.remove('active')
          this.markTocListLiEle.forEach((childEle) => {
            let removeChildEle = childEle.childNodes[1]
            if (removeChildEle) {
              removeChildEle.childNodes.forEach((remEle) => {
                remEle.classList.remove('active')
              })
            }
          })
        } else {
          let tocEle = el.parentNode.parentNode.parentNode.childNodes
          el.classList.remove('active')
          this.$nextTick(() => {
            tocEle &&
              tocEle.forEach((firstEle) => {
                firstEle.classList.remove('active')
                if (firstEle.childNodes[1]) {
                  firstEle.childNodes[1].childNodes.forEach((childEle) => {
                    childEle && childEle.classList.remove('active')
                  })
                }
              })
            data[idx] && data[idx].classList.add('active')
          })
        }
      })
      data[idx] && data[idx].classList.add('active')

      // let markTocLi = document.querySelector('.active')
      // if (data[idx].className.indexOf('second') != -1) {
      //   this.LiHeight =
      //       data[idx].parentNode.parentNode.offsetTop + data[idx].offsetTop
      // } else {
      //   this.LiHeight = data[idx].offsetTop
      // }
    },
    getAnchor() {
      const loading = this.$loading()
      const anchorEleArr = Array.from(
        document.getElementsByClassName('reference-link')
      )
      const anchorEle = anchorEleArr.find((el) => {
        const name = el.getAttribute('name')
        if (name === this.hash) {
          return el
        }
      })
      if (anchorEle) {
        clearTimeout(this.anchorTimer)
        this.$nextTick(() => {
          this.anchorTimer = setTimeout(() => {
            anchorEle.scrollIntoView()
            loading.close()
          }, 500)
        })
      }
      loading.close()
    },
    scrollFun() {
      const scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop
      this.fixMenu(scrollTop)
      if (!this.isClick) {
        this.debounce(this.setCurItemActive(scrollTop), 2000)
      }
    },
    search() {
      if (this.keyword) {
        this.$router.push({
          name: 'search',
          params: { keyword: this.keyword, doc_type: this.doc_type }
        })
      }
    },
    debounce(fn, time) {
      let timeout = null
      return () => {
        if (timeout !== null) {
          clearTimeout(timeout)
        } else {
          timeout = setTimeout(fn, time)
        }
      }
    },
    judgeArr(obj) {
      return obj instanceof Array
    },
    async fixMenu(scrollTop) {
      this.$nextTick(() => {
        this.isFix = scrollTop > 95
        if (this.markTocEle) {
          // this.markTocEle.style.top = scrollTop > 94 ? scrollTop + 'px' : '94px'
        }
      })
    },
    // 扁平化标签数组
    flatEleArr(arr) {
      return arr.reduce((prev, cur) => {
        return prev.concat(
          cur.childNodes[1]
            ? this.flatEleArr(Array.from(cur.childNodes))
            : cur.nodeName !== 'LI'
              ? cur.parentNode
              : cur
        )
      }, [])
    },
    setCurItemActive(scrollTop) {
      this.$nextTick(() => {
        const len = this.menuLists.length
        for (let i = 0; i < len; i++) {
          if (scrollTop >= this.menuLists[i].top + 94) {
            this.removeTocActiveClass(
              this.flatEleArr(Array.from(this.markTocListLiEle)),
              i
            )
          }
        }
      })
    },
    setMarkTocHeight() {
      this.$nextTick(() => {
        const maxHeight =
          this.getEle('body').offsetHeight - 30 - 70 - 124 + 'px'
        if (this.markTocListEle) {
          this.markTocListEle.parentNode.style.height = maxHeight
        }
      })
    },
    initCodeHighLight() {
      this.$nextTick(() => {
        document.querySelectorAll('pre code').forEach((block) => {
          // 清空className的值，让自动分配，否则不支持的语言会报错
          if (block.className) {
            block.className = ''
          }
          hljs.highlightBlock(block)
          hljs.lineNumbersBlock(block)
        })
      })
    },
    // 递归扁平化右侧菜单数组
    flatArr(arr) {
      return arr.reduce((prev, cur) => {
        return prev.concat(
          cur.childNodes[0]
            ? this.flatArr(Array.from(cur.childNodes))
            : cur.parentNode
        )
      }, [])
    },
    getmarkTocListArr() {
      // 获取页面中markdownListmenu 及到顶部的高度
      clearTimeout(this.getEleTimer)
      this.menuLists = []
      this.$nextTick(() => {
        if (this.markTocListEle) {
          const aEleArr = Array.from(
            // this.getEle('#docContent').getElementsByTagName('a')
            this.getEle('#docContent').querySelectorAll(
              'h1, h2, h3, h4, h5, h6'
            )
          )
          this.markTocListLiEle.forEach((el) => {
            // const name = el.childNodes[0].text
            this.flatArr(Array.from(el.childNodes)).forEach((menuText) => {
              const name = decodeURIComponent(menuText.href.split('#')[1])
              let top = ''
              this.getEleTimer = setTimeout(() => {
                aEleArr.forEach((item) => {
                  if (
                    item.id.replace(/\s|\?|[\uff1f]/g, '') ===
                    name.replace(/\s|\?|[\uff1f]/g, '')
                  ) {
                    top = item.offsetTop
                  }
                })
                this.menuLists.push({ name, top })
              })
            })
          }, 2000)
        }
      })
    },
    async handleClickMarkTocItem(index = 0) {
      // 处理点击有点列表时切换选中class
      this.$nextTick(() => {
        const localBodyEle = this.getEle('#docContent')
        localBodyEle.addEventListener(
          'click',
          (e) => {
            clearTimeout(this.timer)
            const curTocEle = e.target.parentNode.parentNode
            if (curTocEle.classList.contains('markdown-toc-list')) {
              const idx = e.target.parentNode.getAttribute('data-id')
              this.isClick = true
              this.removeTocActiveClass(this.markTocListLiEle, idx)
              this.timer = setTimeout(() => {
                this.isClick = false
              }, 3000)
            } else {
              const curTocSecEle =
                e.target.parentNode.parentNode.parentNode.childNodes
              const secIdx =
                e.target.parentNode.parentNode.getAttribute('data-id')
              this.removeTocActiveClass(curTocSecEle, secIdx)
            }
          },
          false
        )
      })
    },
    addClass() {
      this.markTocListLiEle = this.markTocListEle.childNodes
      this.markTocListLiEle[0].classList.add('active')
      this.markTocListLiEle.forEach((el, index) => {
        let secondEle = el.childNodes[1]
        if (secondEle) {
          secondEle.childNodes.forEach((secEl, secIndex) => {
            secEl.setAttribute('data-id', secIndex)
          })
        }
        el.setAttribute('data-id', index)
      })
    },
    async getHtml(project_id, doc_id, isClickMenu = true) {
      if (!isClickMenu) {
        this.hash = ''
      }
      const params = {
        project_id: project_id,
        doc_id: doc_id
      }
      const loading = this.$loading()
      const { data } = await this.$fetch('getDocumentJson', params)
      try {
        this.$router.push({ name: 'docs/show', params: { id: doc_id } })
        let buttonData = ''
        if (data.body.indexOf('\r\n\r\n[REQUEST:API_BUTTON]') > 0) {
          buttonData = data.body.replace(
            '[REQUEST:API_BUTTON]',
            '<button>调试</button>'
          )
        } else {
          buttonData = data.body.replace(
            '\r\n[REQUEST:API_BUTTON]',
            '<br><button>调试</button>'
          )
        }
        this.body = buttonData
        this.bread = data.bread
        this.$nextTick(() => {
          this.initCodeHighLight()
          this.setMarkTocHeight()
          this.markTocEle = this.getEle('.markdown-toc')
          this.markTocListEle = this.getEle('.markdown-toc-list')
          if (this.markTocListEle) {
            this.addClass()
          }
          this.handleClickMarkTocItem()
          this.getmarkTocListArr()
          if (this.hash) {
            this.getAnchor()
          }
          this.getActiveId()
          loading.close()
        })
      } catch (e) {
        loading.close()
        console.log(e)
      }
    },
    async getLeftMenu() {
      const loading = this.$loading()
      const { data } = await this.$fetch('getLeftMenu', { doc_id: this.docId })
      try {
        this.menuData = data.menu
        this.doc_type = data.projectInfo.doc_name
        this.project_id = data.projectInfo.project_id
        this.getHtml(this.project_id, this.docId)
        loading.close()
      } catch (e) {
        loading.close()
        console.log(e)
      }
    }
  },
  beforeDestroy() {
    clearTimeout(this.timer)
    clearTimeout(this.getEleTimer)
    clearTimeout(this.anchorTimer)
  },
  destroyed() {
    window.removeEventListener('popstate', this.goBack, false)
    document.removeEventListener('mousewheel', this.scrollFun, false)
  }
}
</script>

<style lang="less" scoped>
@import '../../assets/less/varible.less';
.detail-wrap::v-deep {
  p {
    line-height: 1.5;
    margin: 10px 0;
  }
  ul,
  ol {
    padding-left: 12px;
  }
  li {
    list-style-type: disc;
  }
  li,
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    // list-style-type: disc;
    color: @hoversColor;
  }
  h1 {
    font-size: 22px;
  }
}
.hover {
  background-color: #f0f6ff;
  color: @mainColor;
}
.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.detail-wrap {
  background: #f6f7f9;
  padding-top: 20px;
  font-size: 14px;

  .detail {
    display: flex;
    &__menu {
      a {
        color: #333;
      }
      position: relative;
      width: 224px;
      height: calc(100vh - 94px);
      background: @whiteBcgColor;
      margin-right: 10px;
      box-sizing: border-box;
      color: #333;
      &-nav {
        padding: 24px 12px 0;
      }
      h3 {
        font-size: 24px;
        font-weight: 500;
        line-height: 33px;
        margin-bottom: 20px;
      }
      &-name {
        font-size: 16px;
        font-weight: 500;
        line-height: 22px;
        margin-bottom: 10px;
      }
      &-search {
        width: 100%;
        position: relative;
        margin-bottom: 15px;
        /deep/ .el-input {
          &__inner {
            height: 36px;
            padding-right: 37px;
            line-height: 36px;
            border-color: #ccc;
            border-radius: 2px;
            color: @hoversColor;
            font-weight: 500;
          }
        }
        i {
          font-size: 17px;
          color: #ccc;
          position: absolute;
          top: 50%;
          right: 10px;
          transform: translateY(-50%);
        }
      }
      .line {
        width: 100%;
        height: 1px;
        background: #dedede;
      }
      .menu {
        height: calc(100vh - 94px - 160px + 138px);
        overflow-y: auto;
        padding: 12px 12px;
        overflow-x: hidden;
        li {
          list-style-type: none;
        }
        /deep/ .el-menu {
          padding-left: 0 !important;
          border-right: 0;
          background: @whiteBcgColor;
          color: #333;
          .el-menu-item {
            height: 40px;
            line-height: 40px;
            // padding-left: 24px !important;
            &.is-active {
              color: @activeColor;
              background: #f0f6ff;
              &.thirdMenu::before {
                background: @mainColor;
              }
            }
            &:hover {
              .hover;
              border-radius: 4px;
            }
          }
          .el-submenu__title {
            &:hover {
              .hover;
              border-radius: 4px;
            }
          }
        }
        .first {
          color: @hoversColor;
          padding-left: 12px !important;
        }
        .first-menu {
          .el-submenu__title {
            span {
              color: @hoversColor;
            }
          }
          &.is-active {
            .el-submenu__title {
              span {
                font-weight: 550;
              }
            }
            .childMenu {
              .el-submenu__title {
                span {
                  color: #5c6670;
                  font-weight: 400;
                }
              }
            }
          }
        }
        .secondMenu {
          color: #5c6670;
          padding-left: 24px !important;
        }
        /deep/ .childMenu {
          font-weight: 400;
          .el-submenu__title {
            padding: 0 30px 0 24px !important;
            .ellipsis;
            span {
              color: #5c6670;
            }
          }
          &.thirdMenu {
            color: #5c6670;
            padding-left: 36px !important;
            max-width: 200px;
            .ellipsis;
            // padding-left: 10px !important;
            // &::before {
            //   content: '';
            //   display: block;
            //   position: absolute;
            //   left: 0px;
            //   top: 50%;
            //   transform: translateY(-50%);
            //   width: 4px;
            //   height: 4px;
            //   background: #666;
            //   border-radius: 50%;
            // }
          }
        }
        /deep/ .el-submenu {
          &__title {
            height: 40px;
            line-height: 40px;
            padding-left: 12px !important;
          }
          .el-menu-item {
            // padding-left: 20px !important;
            height: 40px;
            line-height: 40px;
          }
        }
        .el-submenu.is-opened > .el-submenu__title .iconicon-xialajiantou {
          transform: rotate(180deg);
        }
        .iconicon-xialajiantou {
          position: absolute;
          color: #333;
          right: 10px;
          border-right: 0px solid transparent !important;
        }
      }
    }
    &__content {
      position: relative;
      flex: 1;
      background: #fff;
      box-sizing: border-box;
      .con {
        padding: 20px 20px 50px 20px;
        /deep/ a {
          color: @mainColor;
          &:hover {
            text-decoration: underline;
          }
        }
      }
      &-breadcrumb::v-deep {
        line-height: 20px;
        color: @placeHolderColor;
        a {
          // color: @placeHolderColor !important;
          &:not(:last-child) {
            font-weight: 500;
            color: @placeHolderColor;
          }
          &:hover {
            color: @mainColor!important;
          }
          &:last-child {
            color: @hoversColor;
          }
        }
        span {
          padding: 0 6px;
        }
      }
      &-body {
        width: 698px;
        min-height: 100vh;
        /deep/ button {
          width: 60px;
          height: 32px;
          background: @mainColor;
          border-radius: 4px;
          color: #fff;
          border: 0;
          position: relative;
          left: 91%;
          top: 50px;
          &:hover {
            opacity: 0.9;
          }
          cursor: pointer;
        }
        &::v-deep #title {
          color: #333;
          font-size: 28px;
          font-weight: 500;
          .metadata {
            display: block;
            margin-top: 5px;
            font-size: 14px;
            color: #666;
          }
        }
        &::v-deep img {
          max-width: 698px;
        }
        /deep/ ul {
          li {
            margin-bottom: 10px;
          }
        }
        /deep/ .markdown-toc {
          padding: 10px 0 10px 30px;
          line-height: 25px;
          width: 159px;
          position: absolute;
          top: 94px;
          right: -1px;
          overflow-y: auto;
          overflow-x: hidden;
          &-list {
            position: relative;
            margin: 0;
            border-left: 2px solid #d5deec;
            padding: 5px 10px 5px 0;
            &::before {
              top: -10px;
              left: -6px;
            }
            &::after {
              bottom: -10px;
              left: -6px;
            }
            .first {
              a {
                color: #5c6670;
                width: 112px;
                display: block;
                .ellipsis;
              }
              ul {
                padding: 0;
                margin-top: 10px;
              }
              .second {
                div {
                  width: 123px;
                  .ellipsis;
                }
                a {
                  color: #9aa3ac;
                }
                &.active {
                  &::before {
                    content: '';
                    width: 2px;
                    height: 32px;
                    background: @mainColor;
                    position: absolute;
                    left: -14px;
                    top: 5%;
                    margin-top: -7px;
                  }
                  a {
                    color: @mainColor;
                  }
                }
              }
            }
            li {
              width: 162px;
              position: relative;
              padding-left: 12px;
              list-style-type: none;
              &:not(:last-child) {
                margin-bottom: 10px;
              }
              &:last-child {
                margin-bottom: 0;
              }
              &.active {
                &::before {
                  content: '';
                  width: 2px;
                  height: 32px;
                  background: @mainColor;
                  position: absolute;
                  left: -2px;
                  top: 1%;
                  margin-top: -6px;
                }
                .second {
                  a {
                    color: #9aa3ac;
                  }
                }
                a {
                  color: @mainColor;
                }
              }
              a {
                color: #333;
                font-size: 14px;
                line-height: 22px;
                &:hover {
                  color: @mainColor;
                }
              }
            }
          }
        }
        /deep/ table:not(.hljs-ln) {
          empty-cells: show;
          vertical-align: top;
          width: 100%;
          overflow-x: auto;
          max-width: 12px 0 24px;
          td,
          th {
            min-width: 106px;
            padding: 8px 12px;
            font-size: 12px;
            text-align: left;
            word-wrap: break-word;
            word-break: break-all;
            color: #333;
          }
          th {
            font-weight: 600;
            background-color: #f7f7f7;
            border: 1px solid transparent;
            white-space: wrap;
            box-sizing: border-box;
            &:first-child {
              border-top-left-radius: 4px;
            }
          }
          tbody {
            td {
              border: 1px solid #f0f3f6;
              img {
                max-width: 240px;
                width: 100%;
                height: auto;
              }
            }
          }
        }
        /deep/#title {
          .metadata {
            color: @placeHolderColor;
            margin-top: 16px;
          }
        }
      }
    }
    &.fix {
      .detail__menu {
        position: fixed;
        top: 0;
        height: 100vh;
      }
      .detail__content {
        margin-left: 234px;
        /deep/.markdown-toc {
          position: fixed;
          right: calc((100vw - 1220px) / 2);
          top: 125px;
        }
      }
    }
  }
  .copyright {
    width: 100%;
    height: 77px;
    line-height: 77px;
    background: #f9fbfe;
  }
}
</style>
